<template>
  <base-card>
    <v-card-text>
      <div>
        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
        >
          <v-row class="align-center mb-6">
            <v-col
              cols="12"
              sm="4"
              md="3"
              lg="2"
              offset-lg="2"
            >
              <v-text-field
                v-model="city"
                clearable
                :label="`${$t('address.city')}*`"
                :rules="cityRules"
              />
            </v-col>

            <v-col
              cols="12"
              sm="4"
              md="3"
              lg="2"
            >
              <v-text-field
                v-model="street"
                clearable
                :label="`${$t('address.streetName')}*`"
                :rules="streetRules"
              />
            </v-col>

            <v-col
              cols="12"
              sm="4"
              md="2"
            >
              <v-text-field
                v-model="streetNumber"
                clearable
                :label="$t('address.streetNumber')"
              />
            </v-col>

            <v-col
              cols="12"
              md="4"
              class="d-flex justify-center justify-md-start"
            >
              <v-btn
                class="primary px-4 mr-4"
                @click="searchAccesses()"
              >
                {{ $t("common.search") }}
              </v-btn>

              <v-btn
                class="error px-4"
                @click="resetForm">
                {{ $t("common.reset") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

        <v-alert
          border="left"
          outlined
          type="error"
          elevation="2"
          class="mb-6 mt-2 text-left"
          dismissible
          text
          v-if="errorMessage"
        >
          {{ errorMessage }}
        </v-alert>
      </div>

      <v-data-table
        :headers="headers"
        :items="pageData"
        hide-default-footer
        item-key="id"
        class="eg-table-bnone eg-table-td-py-md mt-2 px-4 mainText--text"
      >
        <template v-slot:item.address="{item}">
          <span>{{ item.address.street }} {{ item.address.number }} {{ item.address.littera }}</span>
          <br />
          <span>{{ util.formatPostalCode(item.address.postalCode) }} {{ item.address.city }}</span>
        </template>

        <template v-slot:item.coordinates="{item}">
          <div class="pt-1">
            {{
              $t("address.latitude", {
                latitude: item.coordinates.latitude,
              })
            }}
          </div>
          <div>
            {{
              $t("address.longitude", {
                longitude: item.coordinates.longitude,
              })
            }}
          </div>
          <div class="pb-1">
            {{
              $t("address.projection", {
                projection: item.coordinates.projection,
              })
            }}
          </div>
        </template>

        <template v-slot:item.realEstate="{item}">
          <span>
            {{ item.realEstate.label }}
            ({{ item.realEstate.municipality }})
          </span>
        </template>

        <template v-slot:item.relatedPointIds="{item}">
          <div class="py-1">
            <div
              v-for="(point, ind) in item.relatedPointIds"
              :key="ind"
            >
              {{ point.name }} - {{ util.getStokabTypeName(point.type) }}
            </div>
          </div>
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-actions class="justify-center">
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        color="primary"
        :total-visible="7"
        circle
      />
    </v-card-actions>
  </base-card>
</template>

<script>
  import { mapActions } from 'vuex'

  import util from '@/utils'

  import TagGroup from '../components/TagGroup'

  export default {
    metaInfo: {
      title: 'Search Stokab Networks',
    },

    components: {
      TagGroup,
    },

    data () {
      return {
        tableData: [],
        pageData: [],
        limit: 10,
        currentPage: 1,
        totalPages: 1,
        headers: [],
        city: '',
        street: '',
        streetNumber: '',
        errorMessage: '',
        util: util,
        validForm: true,
        cityRules: [
          (v) => !!v || this.$t('error.fieldRequired', { field: this.$t('address.city') }),
        ],
        streetRules: [
          (v) => !!v || this.$t('error.fieldRequired', { field: this.$t('address.streetName') }),
        ],
      }
    },

    watch: {
      currentPage(val) {
        this.paginationData(val)
      }
    },

    mounted () {
      this.headers = [{
        text: this.$t('common.number'),
        value: 'index',
        width: 80,
      }, {
        text: this.$t('address.address'),
        value: 'address',
        width: 240,
      }, {
        text: this.$t('address.coordinates'),
        value: 'coordinates',
        width: 220,
      }, {
        text: this.$t('access.cityArea'),
        value: 'cityArea',
        width: 200,
      }, {
        text: this.$t('access.district'),
        value: 'district',
        width: 200,
      }, {
        text: this.$t('access.realEstate'),
        value: 'realEstate',
        width: 280,
      }, {
        text: this.$t('access.availability'),
        value: 'fiberStatus',
        width: 200,
      }, {
        text: this.$t('access.odf'),
        value: 'relatedPointIds',
        width: 200,
      }]
    },

    methods: {
      ...mapActions({
        getAvailabilityByAddress: 'getAvailabilityByAddress',
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
      }),

      async searchAccesses() {
        this.errorMessage = ''

        const isValid = this.$refs.form.validate()

        if (!isValid) {
          return
        }

        this.setLoading(true)
        this.setLoadingText(`${this.$t('common.loading')} ${this.$t('common.data')}...`)

        try {
          const payload = {
            city: this.city,
            street: this.street,
            number: this.streetNumber,
          }

          const response = await this.getAvailabilityByAddress(payload)

          if (response.message) {
            this.errorMessage = response.message
          } else {
            this.tableData = response.map((item, index) => {
              if (item.pointId) {
                item.relatedPointIds.push(item.pointInfo)
              }

              item.index = index + 1
              return item
            })

            this.paginationData()
          }

          this.totalPages = Math.ceil(this.tableData.length / this.limit)
        } catch (error) {
          this.totalPages = 1
          this.tableData = []
          this.currentPage = 1
          this.errorMessage = util.getErrorResponse(error)
        }
        this.setLoading(false)
      },

      paginationData(currentPage = 1) {
        this.currentPage = currentPage

        this.pageData = this.tableData.filter(
          (item) =>
            item.index > (currentPage - 1) * this.limit &&
            item.index <= currentPage * this.limit
        )
      },

      resetForm() {
        this.errorMessage = ''
        this.city = ''
        this.street = ''
        this.streetNumber = ''
        this.tableData = []
        this.pageData = []
        this.currentPage = 1
        this.totalPages = 1
      },
    },
  }
</script>
